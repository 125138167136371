*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  overflow-y: scroll;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

html,
body {
  height: 100%;
}

body {
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

button {
  cursor: pointer;
  border:none;
  background-color: transparent;
}
