.headerUnit{
  h1{
    font-family: var(--font-en);
    text-align:center;
    background-color: #ffffff;
    padding:16px;
    font-size:2.4rem;
    letter-spacing: .025em;
  }
}

.footerUnit{
  font-family: var(--font-en);
  text-align:center;
  font-size:1.3rem;
  font-weight: 600;
  padding: 12px;
  letter-spacing: .075em;
  background-color: #ffffff;
}