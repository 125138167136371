@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Noto+Sans+JP:wght@300;400;700&display=swap");

:root {
  --font-ja: "Noto Sans JP", sans-serif;
  --font-en: "Montserrat", sans-serif;
}

body {
  font-family: var(--font-ja);
  color: #343434;
  background-color: #eef0f5;
}

.wrapper {
  width: 640px;
  margin: 0 auto;
  padding: 32px 16px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  .leadText {
    font-size: 1.3rem;
    text-align: center;
  }
}

.monsterBoxes {
  display: flex;
  gap: 16px;
  margin-top: 24px;

  .box {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 100%;

    @media screen and (max-width: 600px) {
      padding: 16px 12px;
    }

    dt {
      text-align: center;
      font-weight: 600;
      font-size: 1.3rem;

      @media screen and (max-width: 600px) {
        font-size: 1.2rem;
      }
    }

    dd {
      text-align: center;
      margin-top: 8px;
      font-size: 4.2rem;
      font-weight: 600;

      @media screen and (max-width: 600px) {
        font-size: 3.8rem;
      }

      .lText {
        font-family: var(--font-en);
      }

      .mText {
        font-size: 2.4rem;

        @media screen and (max-width: 600px) {
          font-size: 2.2rem;
        }
      }

      .sText {
        font-size: 1.6rem;
        padding-left: 0.35em;

        @media screen and (max-width: 600px) {
          font-size: 1.6rem;
        }
      }
    }
  }
}

.monsterList {
  margin-top: 16px;

  .item {
    margin-top: 10px;

    .monsterItem {
      border: none;
      background-color: #fff;
      color: #343434;
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 8px;
      padding: 16px;
      line-height: 1;
      text-align: left;

      .id {
        --size: 2.5em;
        text-align: center;
        background-color: #eef0f5;
        display: inline-block;
        width: var(--size);
        height: var(--size);
        line-height: var(--size);
        border-radius: 8px;
        font-size: 1.4rem;
        font-family: var(--font-en);
        font-weight: 600;
        margin-right: 32px;
        @media screen and (max-width: 500px) {
          margin-right: 16px;
          font-size: 1.2rem;
        }
      }

      .name {
        font-weight: 600;
        font-size: 1.8rem;
        width: 9em;
        margin-right: 16px;
        white-space: nowrap;

        @media screen and (max-width: 600px) {
          font-size: 1.6rem;
        }
        @media screen and (max-width: 500px) {
          font-size: 1.4rem;
        }
        @media screen and (max-width: 375px) {
          margin-right: 8px;
        }
      }

      .number {
        letter-spacing: 0.5em;
        @media screen and (max-width: 500px) {
          font-size: 1.3rem;
        }
        @media screen and (max-width: 375px) {
          font-size: 1.3rem;
          letter-spacing: 0.25em;
        }
      }

      .complete {
        margin-left: auto;
        font-size: 1.3rem;
        font-family: var(--font-en);
        font-weight: 600;
        color: #a49ebc;
        display: none;
        @media screen and (max-width: 500px) {
          font-size: 1.1rem;
        }
        @media screen and (max-width: 500px) {
          font-size: 1rem;
        }
      }
    }
  }

  .item.max {
    .complete {
      display: inline-block;
    }
  }
  .item.get {
    .id {
      background-color: #7b44d5;
      color: #fff;
    }
  }
}

.buttonUnit {
  text-align: center;

  .deleteButton {
    display: inline-block;
    margin-top: 64px;
    background-color: #cc0000;
    color: #fff;
    padding: 10px 16px 12px;
    font-weight: 600;
    line-height: 1;
    border-radius: 8px;
  }
}

.caution {
  margin-top: 16px;
  background-color: #fff;
  border: 2px solid #eb6800;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  p {
    font-size: 1.3rem;
    font-weight: 700;
    color: #eb6800;
    line-height: 1.1;
  }
}
